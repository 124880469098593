import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Solucoes from "../components/solucoes"
import Servicos from "../components/servicos"
import SEO from "../components/seo"
import "../components/applify/sass/style.scss"
import "./css/index.scss"
import aboutImage from "../images/index/about.svg";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="" keywords={[`ecommerce`, `loja virtual`, `magento`]} />
    <div className="main" role="main">
      <div className="ui-hero hero-lg ui-gradient-sc ui-waves hero-svg-layer-1 sp-horizontal sp-has-buttons">
        <div className="container">
          <div className="row">
            <div className="col-sm-5" data-vertical_center="true" data-vertical_offset="16">
              <h1 className="heading animate fade-in-up-big" data-show="fade-in-up-big" data-delay="100">
                Inovamos o seu Negócio
              </h1>
              <p className="paragraph animate fade-in-up-big" data-show="fade-in-up-big" data-delay="400">
                O objetivo da <strong>System Code</strong> é entender sobre o seu negócio e então oferecer soluções para simplificar e inovar, seja em seus processos internos ou voltada aos seus clientes.
              </p>
              <div className="actions animate fade-in-up-big" data-show="fade-in-up-big" data-delay="600">
                <Link to="sobre" className="btn ui-gradient-blue shadow-xl ui-gradient-animator">Sobre Nós</Link>
              </div>
            </div>
            <div className="col-sm-7 animate fade-in-left" data-show="fade-in-left" data-delay="400">
              <img src={aboutImage} alt="System Code"
                   data-uhd data-max_width="1000" className="responsive-on-lg" />
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="section">
        <div className="container ui-icon-blocks ui-blocks-h icons-lg">
          <div className="section-heading center">
            <h2 className="heading text-blue-sc">
              Nossos Serviços
            </h2>
            <p className="paragraph">
              Conheça os principais serviços que a <strong>System Code</strong> oferece.
            </p>
          </div>
          <Servicos />
        </div>
      </div>

      <div className="section bg-light">
        <div className="container">
          <div className="section-heading center">
            <h2 className="heading text-blue-sc">
              Nossas Soluções
            </h2>
            <p className="paragraph">
              Possuimos soluções inovadoras para o seu negócio.
            </p>
          </div>
          <Solucoes />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
